.App {
  text-align: center;
  background-color: #FA585F;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: 10;
}

.logo {
  max-width: 40vw;
  min-width: 16rem;
  margin-top: 40px;
}

.logoSmall {
  height: 3em;
  width: auto;
}

.Karanteneedition {
  margin-top: 0px;
  padding-top: 0px;
}

.bacground-ol {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 50vh;
  z-index: 0;
  background-size: auto 50vh;
  background-image: url('assets/images/bg-ol.png');
}

.button {
  background-color: white;
  color: black;
  border: solid 1px whitesmoke;
  padding: 15px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 60vw;
  max-height: 5rem;
  width: 15em;
}

.button:hover {
  background-color: whitesmoke;
}

.button-start {
  justify-self: flex-end;
}
.button-exit {
  justify-self: flex-end;
  background-color: transparent;
  border: none;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  max-width: 60vw;
  max-height: 5rem;
  width: 15em;
}

.button-exit:hover h2 {
  color: whitesmoke;
}

.input {
  background-color: white;
  color: black;
  border: none;
  padding: 15px 32px;
  margin: 10px;
  text-align: left;
  font-size: 16px;
  border-radius: 10px;
  max-width: -webkit-fill-available;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.userField {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: black;
  border: solid 1px whitesmoke;
  padding: 15px;
  margin: 10px;
  text-align: left;
  border-radius: 10px;
  max-width: 60vw;
  width: 15em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.userSmallField {
  width: 2em;
  height: 2em;
  border: none;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

.logoContainer {
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.homeContainer {
  flex: 2;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  z-index: 10;
}

.lobbyContainer {
  flex: 1;
  display: flex;/*
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr; */
  z-index: 10;
  margin-left: 10vw;
  margin-right: 10vw;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.inputContainer {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  z-index: 10;
}

h1, h2, h3, p {
  color: white;
  margin: 0px;
}

h2 + h3 {
  padding-top: 10px;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: darkblue;
  color: white;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}




.GameContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.BasicContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin-left: 10vw;
  margin-right: 10vw;
}

.DilemmaTop {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}

.DilemmaBottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.OptionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
}

.OptionBox {
  display: flex;
  outline: thick;
  background-color: white;
  color: black;
  border: none;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
}

.OptionBox h3 {
  color: black;
}

.OptionBox:hover {
  background-color: whitesmoke;
}

/* Sånn hoover ikke er på når man har valgt */
.OptionBoxVoted { 
  display: flex;
  outline: thick;
  background-color: white;
  color: black;
  border: none;
  padding: 15px 32px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
}

.OptionBoxVoted h3 {
  color: black;
}

.OptionCountBox {
  padding-left: 1rem;
}

.OptionCountBox h2 {
  color: black;
}


.PhoneStoreImage {
  height: 4rem;
  width: auto;
  border-radius: 1rem;
  padding: 10px;
}

.TopContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
}

.BottomContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 10vh;
  padding-left: 2.5vw;
}

.NextLastContainer {
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.NextLastButtonContainer {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 10vw;
}


.NextLastButton {
  border: none;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  flex: 1;
  align-self: stretch;
}

.NextLastButton:hover img {
  transform: scale(1.2);
}

.NextLastImage {
  width: 2em;
  height: auto;
}

.ExitButton {
  justify-self: flex-end;
  width: 2em;
  height: 2em;
  border: none;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}

.ExitButton:hover img {
  transform: scale(1.2);
}

.ExitImage {
  width: 2em;
  height: 2em;
}
